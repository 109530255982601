import type { MetaObject } from 'nuxt/schema';

const faviconLink = [
    {
        rel: 'shortcut icon',
        type: 'image/x-icon',
        href: '/favicons/favicon.ico'
    },
    {
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
        href: '/favicons/favicon-16x16.png'
    },
    {
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
        href: '/favicons/favicon-32x32.png'
    },
    {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: '/favicons/apple-touch-icon.png'
    },
    {
        rel: 'mask-icon',
        href: '/favicons/safari-pinned-tab.svg',
        color: '#d1cd33'
    },
    {
        rel: 'manifest',
        href: '/favicons/site.webmanifest',
        crossorigin: 'use-credentials'
    }
];

const faviconMeta = [
    { name: 'msapplication-TileColor', content: '#0f0f0f' },
    { name: 'msapplication-config', content: '/favicons/browserconfig.xml' },
    { name: 'theme-color', content: '#0f0f0f' }
];

export const faviconHead = {
    meta: faviconMeta,
    link: faviconLink
} as MetaObject;
